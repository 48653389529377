import React, { useMemo, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Box, CssBaseline, TextField, ThemeProvider } from "@mui/material";
import dayjs from "dayjs";
import TextInput from "./TextInput";

import { CacheProvider } from "@emotion/react";
import { createMyTheme, createRtlCache } from "components/theme";
import createCache from "@emotion/cache";

const MobilePicker = ({ onChange, value, label, error, lang }) => {
    const [time, setTime] = useState(new Date());


    const direction = useMemo(() => (lang === 'ar' ? 'rtl' : 'ltr'), [lang]);
    const theme = useMemo(() => createMyTheme(direction), [direction]);

    const cache = useMemo(() => {
        return direction === 'rtl' ? createRtlCache() : createCache({ key: 'mui' });
    }, [direction]);

    return (
        // <CacheProvider value={cache}>
        //     <ThemeProvider theme={theme}>
        //         <CssBaseline />
        // <div dir={direction}>

        <LocalizationProvider dateAdapter={AdapterDayjs}>

            <MobileDatePicker
                value={value}
                onChange={onChange}
                sx={{ width: '100%' }}
                disableFuture
                renderInput={(params) => (
                    <TextInput
                        {...params}
                        size={'small'}
                        error={error}
                    />
                )}
            />

        </LocalizationProvider>
        // </div>
        //     </ThemeProvider>
        // </CacheProvider>

    );
};

export default MobilePicker;
