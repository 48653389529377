import React from 'react'
import Header from './header'
import Footer from './footer'
import { Box, Button, Typography } from '@mui/material'
import Img from '../asserts/images/Process_Ring.png'
import Lottie from 'lottie-react'
import animationData from '../asserts/images/success.json'
import { useNavigate } from 'react-router-dom'

let container =
    "max-w-screen-sm max-w-screen-md max-w-screen-lg max-w-screen-xl max-w-screen-2xl m-auto p-6";

function SuccessPage() {

    const Navigate = useNavigate()

    return (
        <div>
            <Header />
            <div
                className={container}
                style={{
                    height: '58rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80vh',
                        textAlign: 'center',
                        padding: '20px',
                        boxSizing: 'border-box',
                        gap: '20px'
                    }}
                >
                    {/* <Box
                        component="img"
                        src={Img}
                        alt="Success"
                        sx={{
                            width: "230px",
                            height: '230px',
                            marginBottom: '20px',
                        }}
                    /> */}
                    <Lottie animationData={animationData} style={{ height: '300px', width: "300px" }} />
                    <Typography fontWeight={800} fontSize={"30px"} color={"#DB3B44"}    >
                        Success! Your Application Has Been Submitted
                    </Typography>
                    <Typography sx={{
                        display: 'flex',
                        textAlign: "center",
                        flexDirection: 'column',
                        gap: '15px',
                        color: "#333333",

                    }} variant="body1">
                        <Typography
                            fontSize="20px"
                        >
                            Thank you for applying!
                        </Typography>
                        <Typography
                            fontSize="20px"

                        >

                            We have successfully received your CV and application for the position at Mobility & Equipment Sector. Our team is excited to review your qualifications and experience.
                        </Typography>
                    </Typography>
                    <Button
                        variant='contained'
                        sx={{
                            background: "#DB3B44",
                            '&:hover': {
                                backgroundColor: "#DB3B44",
                            },
                        }}
                        onClick={() => Navigate('/')}
                    >
                        Back to Home
                    </Button>
                </Box>

            </div>

            <Footer
                position='fixed'
            />

        </div>
    )
}

export default SuccessPage