import React from 'react'
import GacLogo from "logo.png";

let container =
    "max-w-screen-sm max-w-screen-md max-w-screen-lg max-w-screen-xl max-w-screen-2xl m-auto p-6";

function Header() {
    return (
        <div>
            <section

                className="bg-[#DB3B44]">
                <div
                    className={`${container} flex justify-center items-center gap-2  flex-warp bg-[#DB3B44]`}
                >
                    <div className="w-96 bg-[#DB3B44]">
                        <a href="/">
                            <img src={GacLogo} className="w-full bg-[#DB3B44]" alt="Logo" />
                        </a>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Header