import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Typography,
    InputAdornment,
    IconButton,
} from "@mui/material";
import IconifyIcon from "../icons";
import React from "react";
import styled from "styled-components";

const TextWrapper = styled.p`
    margin: 0px;
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: 0.15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(58, 53, 65, 0.87);
    font-weight: 600;
    text-decoration: none;
  `;



const SelectField = ({
    label,
    variant,
    fullWidth,
    option,
    multiple,
    onChange,
    style,
    value,
    helperText,
    error,
    onBlur,
    imageView,
    color,
    size,
    disable,
    selectClearValue,
    name,
    readOnly,
    id,
    lang
}) => {

    return (
        <>
            <FormControl variant={variant} fullWidth={fullWidth} error={error}>
                <InputLabel>{label}</InputLabel>
                <Select
                    name={name}
                    id={id}
                    label={label}
                    value={value}
                    style={style}
                    multiple={multiple}
                    onChange={onChange}
                    onBlur={onBlur}
                    color={color}
                    size={size}
                    disabled={disable}
                    // sx={{ textAlign: "right" }}
                    error={error}
                    inputProps={{ readOnly: readOnly }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "right"
                        },
                        getContentAnchorEl: null
                    }}

                >
                    {option.length > 0 ? (
                        imageView ? (
                            option.map((obj, i) => {
                                return (
                                    <MenuItem key={i} value={obj} >
                                        <div style={{ display: "flex", gap: "15px" }}>
                                            <img
                                                style={{ width: "40px", borderRadius: 50 }}
                                                src={
                                                    obj?.image[0]?.url ||
                                                    "https://docs-assets.katomaran.tech/images/images/smart-condo/res_man/2022/10/res_man.png"
                                                }
                                            />
                                            <div>
                                                <TextWrapper>{obj.name}</TextWrapper>
                                                <TextWrapper
                                                    className="res-option"
                                                    style={{ color: "rgba(58, 53, 65, 0.68)" }}
                                                >
                                                    {obj.phone}
                                                </TextWrapper>
                                            </div>
                                        </div>
                                    </MenuItem>
                                );
                            })
                        ) : (
                            option.map((item, index) => (
                                <MenuItem sx={{ textAlign: "right" }} disabled={item?.disabled} id={index} key={index} value={item?.value}>
                                    {item?.label}
                                </MenuItem>
                            ))
                        )
                    ) : (
                        <MenuItem value="">
                            <Typography textAlign={"center"} color={"text.secondary"}>
                                No Option
                            </Typography>
                        </MenuItem>
                    )}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </>
    );
};

export default SelectField;
