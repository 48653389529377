import React, { useState } from "react";

import HomePage from "./homePage";
import SelectField from "components/inputs/selectFeild";

import Header from "./header";
import Footer from "./footer";


let container =
  "max-w-screen-sm max-w-screen-md max-w-screen-lg max-w-screen-xl max-w-screen-2xl m-auto p-6";

export const RecallMainPage = () => {


  const lannguages = [{ label: "English", value: "eng" }, { label: "عربي", value: 'ar' }]

  const [lang, setLang] = useState('eng')

  const onChange = (e) => {
    setLang(e.target.value)
  }

  return (
    <main>
      <Header />
      <div
        className={` flex justify-end items-end m-8`}
      >
        <SelectField
          size={"small"}
          onChange={(e) => {
            onChange(e);
          }}
          value={lang}
          option={lannguages}
        />
      </div>
      <div className={container}>

        <HomePage lang={lang} />
      </div>
      <Footer />
    </main>
  );
};
