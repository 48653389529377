export const LabelsList = {
    english: {
        name: "Name",
        nationality: "Nationality",
        gender: "Gender",
        dob: "Date of Birth",
        age: "Age",
        address: "Address (Country/ Town)",
        mobile: "Mobile No",
        hasExperiencetoWork: "Has Experience to Work in",
    },
    arabic: {
        name: "الاسم",
        nationality: "الجنسية",
        gender: "الجنس (ذكر/ انثى)",
        dob: "تاريخ الميلاد",
        age: "العمر",
        address: "العنوان (الدولة/ المدينة)",
        mobile: "رقم الجوال",
        hasExperiencetoWork: "لدي خبرة بالعمل في مجال",
    }
}

export const WorkListEng = [
    { label: "Vehicle Marketing & Sales", value: "Vehicle Marketing & Sales" },
    { label: "Vehicle Spare Parts", value: "Vehicle Spare Parts" },
    { label: "Vehicle Maintenance & Repair Services", value: "Vehicle Maintenance & Repair Services" },
    { label: "Customer Service", value: "Customer Service" },
    { label: "Finance", value: "Finance" },
    { label: "IT", value: "IT" },
    { label: "Other Admin Jobs", value: "Other Admin Jobs" },
]

export const WorkListArb = [
    { label: " تسويق ومبيعات السيارات", value: " تسويق ومبيعات السيارات" },
    { label: " قطع غيار السيارات", value: " قطع غيار السيارات " },
    { label: "خدمات صيانة واصلاح المركبات", value: "خدمات صيانة واصلاح المركبات" },
    { label: " خدمة العملاء", value: " خدمة العملاء" },
    { label: "المالية", value: "المالية" },
    { label: " تقنية المعلومات", value: " تقنية المعلومات" },
    { label: " وظائف ادارية اخرى", value: " وظائف ادارية اخرى" },
]


// Has experience to work in:
//  Vehicle Marketing & Sales /
//     Vehicle Spare Parts /
//         Vehicle Maintenance & Repair Services /
//             Customer Service /
//                 Finance /
//                 IT /
//                 Other Admin Jobs /
//                     I need to gain practical experience(Please Specify)


// لدي خبرة بالعمل في مجال: تسويق ومبيعات السيارات / قطع غيار السيارات / خدمات صيانة واصلاح المركبات / خدمة العملاء / المالية / تقنية المعلومات / وظائف ادارية اخرى / أود أن اكتسب خبره عملية(يرجى التحديد)