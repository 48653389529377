import React, { useEffect, useState } from 'react'
import Header from './header'
import Footer from './footer';
import { Box, Button, Card, Grid, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowImg from '../asserts/images/apply.png'
import { useNavigate } from 'react-router-dom';
import { getJobAPI } from 'api/resume';

let container =
    "max-w-screen-sm max-w-screen-md max-w-screen-lg max-w-screen-xl max-w-screen-2xl m-auto p-6";

function LandingPage() {
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    useEffect(() => {
        fetchJobOffers()
    }, [])

    const fetchJobOffers = () => {
        getJobAPI()
            .then(({ data }) => {
                setData(data?.data)
                setLoader(false)
            })
    }



    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <main style={{ flex: 1 }}>
                <div className={container}>
                    <div style={{ display: 'flex', textAlign: 'center', flexDirection: 'column', gap: '3px', alignItems: 'center', marginTop: "30px" }}>
                        <Typography color="#333333" variant='h5' fontWeight={500}>
                            We're more than a workplace.
                        </Typography>
                        <Typography color="#333333" fontWeight={500} variant='h5'>
                            We're a family.
                        </Typography>
                    </div>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        fontWeight: 400,
                        lineHeight: '44px',
                        fontSize: '22px',
                        color: '#555555',
                        mt: 5
                    }}>
                        <Typography variant='body'>
                            We know that finding a meaningful and rewarding career can be a long journey. Our goal is to make that process easy for you and to create a work environment that's enriching - one that you'll look forward to every day.
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: "column",
                        gap: '3px',
                        mt: 6,
                        textAlign: 'center',
                        color: "#646D8C"
                    }}>
                        <Typography sx={{ fontWeight: 700, fontSize: '25px' }} variant='body'>{'Interested in Joining Us?'}</Typography>
                        {data?.length > 0 && <Typography sx={{ fontSize: '26px', color: "#DB3B44", fontWeight: 600 }} variant='body'>Current Openings</Typography>}
                    </Box>
                </div>
                <Box sx={{ ml: 5, mr: 5, mb: 5 }}>
                    {
                        loader ? (
                            <Grid container spacing={3}>

                                {Array.from(new Array(6)).map((_, index) => (
                                    <Grid item key={index} xs={12} sm={6} md={3} lg={3} xl={3}>
                                        <Card>
                                            <Skeleton variant="rectangular" height={50} sx={{ margin: '10px' }} />
                                            <Box sx={{
                                                padding: '30px',
                                                textAlign: 'center',
                                                display: "flex",
                                                flexDirection: 'column',
                                                gap: '15px',
                                                background: "#FFF3F2",
                                                alignItems: "center"
                                            }}>
                                                <Skeleton variant="text" width="80%" />
                                                <Skeleton variant="text" width="60%" />
                                                <Skeleton variant="rectangular" width="50%" height={36} />
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            data?.length > 0 ?
                                <>
                                    <Grid container spacing={4} mt={1}>
                                        {
                                            data?.map((val, i) => {
                                                return (
                                                    <Grid item key={i} xs={12} sm={12} md={6} lg={3} xl={3}>
                                                        <Card sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            height: '100%' // Ensures all cards have the same height 
                                                        }}>
                                                            <Typography
                                                                sx={{ padding: '10px', background: "#FEE7E5" }}
                                                                fontSize={"20px"} color={"#000000"} textAlign={"center"}>
                                                                {val?.title}
                                                            </Typography>
                                                            <Box sx={{
                                                                flexGrow: 1, // Takes up the remaining space to push the button to the bottom
                                                                padding: '25px',
                                                                textAlign: 'center',
                                                                display: "flex",
                                                                flexDirection: 'column',
                                                                gap: '15px',
                                                                background: "#FFF3F2",
                                                                alignItems: "center",
                                                            }}>
                                                                <Typography variant='body' fontSize={"16px"} color={"#666666"} textAlign={'center'}>
                                                                    {val?.description}
                                                                </Typography>
                                                                <Button
                                                                    onClick={() => navigate(`/upload_form?alias_name=${val?.alias_name}`)}
                                                                    fullWidth={true}
                                                                    variant='contained' sx={{
                                                                        textTransform: "capitalize",
                                                                        fontSize: "16px",
                                                                        background: "#DB3B44",
                                                                        '&:hover': {
                                                                            backgroundColor: "#DB3B44",
                                                                        },
                                                                        width: isMobile ? "100%" : "75%",
                                                                        borderRadius: '6px',
                                                                        display: 'flex',
                                                                        gap: "5px",
                                                                        alignItems: "center",
                                                                        mt: 'auto' // Margin top auto pushes the button to the bottom
                                                                    }}>
                                                                    <span>
                                                                        Apply
                                                                    </span>
                                                                    <img src={ArrowImg}
                                                                        alt='img'
                                                                        height={"20px"}
                                                                        width={"20px"}
                                                                    />
                                                                </Button>
                                                            </Box>
                                                        </Card>
                                                    </Grid>

                                                )
                                            })
                                        }
                                    </Grid>
                                </> :
                                <div className={container} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    fontWeight: 400,
                                    lineHeight: '44px',
                                    fontSize: '22px',
                                    color: '#555555',
                                    // mt: 5
                                }}
                                >
                                    <Typography variant='body'>
                                        We welcome you to  <span style={{
                                            color: '#DB3B44',
                                            textDecoration: "underline",
                                            cursor: 'pointer'
                                        }}
                                            onClick={() => navigate(`/upload_form`)}
                                        >
                                            submit your CV here,
                                        </span> and we will reach out to you when a suitable position becomes available.
                                    </Typography>
                                </div>

                        )
                    }
                </Box>
            </main>
            <Footer />
        </div>
    )
}

export default LandingPage