

// export default async function ({ uri, method = "GET", body }) {
//   return fetch(apiHost + uri, {
//     method,
//     body,
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//   }).then(async (response) => {
//     if (response.ok) {
//       try {
//         return response.json();
//       } catch (err) {
//         return true;
//       }
//     }
//     const errorMessage = [response.status, await response.json()];
//     throw errorMessage;
//   });
// }

import axios from "axios";
// import { apiHost } from "";
// import { getCookie, setCookie } from "utils/cookies";
// import jwtDecode from "jwt-decode";
// import auth from "configs/auth";
// import { errorHandler } from "utils/errorHandler";
// import * as Sentry from "@sentry/nextjs";


import { apiHost } from "config";

const connection = axios.create({
  baseURL: apiHost,
  headers: {
    "Content-Type": "application/json",
    'Sec-Fetch-Mode': 'cors',
  },
  formHeaders: { "Content-Type": "multipart/form-data" },
});
connection.interceptors.request.use(
  async (config) => {

    if (config.data instanceof FormData) {
      config.headers = { ...config.formHeaders };
    } else {
      config.headers = { ...config.headers };
    }
    return config;
  },
  (error) => {
    // Sentry.captureException(error);
    return Promise.reject(error);
  }
);

connection.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorMessage = error?.response || error;
    const message =
      errorMessage?.data?.data?.[0] ||
      errorMessage?.data?.error ||
      errorMessage?.data?.message ||
      errorMessage?.message;
    // throw new Error(error);

    // Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export default connection;

