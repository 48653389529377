import React, { useState } from "react";
import {
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
    Zoom,
} from "@mui/material";
import IconifyIcon from "components/icons";
// import IconifyIcon from "../icon";

const TextInput = ({
    type = "text",
    label,
    name,
    variant,
    value,
    required,
    disabled,
    readOnly,
    shrink,
    error,
    helperText,
    multiline,
    icon,
    tagPosition,
    customTag,
    focused,
    fullWidth,

    maxRows,
    ref,
    onchange,
    placeholder,
    size,
    rows,
    style,
    onblur,
    iconStyle,
    toolTip,
    className,
    sx
}) => {
    const [showPassword, setShowPassword] = useState(false);

    console.log(sx, "sxxxxxxxxxxxxxxxxxxx");

    const positionProps =
        tagPosition == "start" ? "startAdornment" : "endAdornment";

    return (
        <TextField
            type={showPassword ? "text" : type}
            variant={variant}
            label={label}
            name={name}
            value={value}
            className={className}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
            shrink={shrink}
            error={error}
            helperText={helperText}
            multiline={multiline}
            focused={focused}
            fullWidth={fullWidth}
            inputRef={ref}
            maxRows={maxRows}
            rows={rows}
            placeholder={placeholder}
            size={size}
            onBlur={onblur}
            InputProps={{
                [positionProps]: (
                    <InputAdornment position={tagPosition}>
                        {type == "password" ? (
                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                {IconifyIcon({ icon: showPassword ? "bx:show" : "bx-hide" })}
                            </IconButton>
                        ) : icon && toolTip ? (
                            <Tooltip
                                arrow
                                title={toolTip}
                                placement="top"
                                TransitionComponent={Zoom}
                                TransitionProps={{ timeout: 500 }}
                            >
                                <Typography>
                                    {" "}
                                    <IconifyIcon icon={icon} style={iconStyle} />
                                </Typography>
                            </Tooltip>
                        ) : icon ? (
                            <IconifyIcon icon={icon} style={iconStyle} />
                        ) : (
                            customTag
                        )}
                    </InputAdornment>
                ),
            }}
            inputProps={{ style: sx }}
            style={style}
            onChange={onchange}
        />
    );
};

export default TextInput;
