// import { RecallMainPage } from "views";
import { RecallMainPage } from "views/uploadForm";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "views";
import SuccessPage from "views/sucessPage";
import { Toaster } from "react-hot-toast";
// import HomePage from "views/homePage";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/upload_form" element={<RecallMainPage />} />
          <Route path="/success_page" element={<SuccessPage />} />
        </Routes>
      </BrowserRouter>
      {/* <ReactHotToast> */}
      <Toaster position={"top-right"} toastOptions={{ className: 'react-hot-toast' }} />
      {/* </ReactHotToast> */}
    </div>
  );
}

export default App;
