
import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
// import moment from "moment";
import { Chip } from "@mui/material";
import moment from "moment";

let array1 = [];
let array2 = [];
let array3 = [];
let array4 = [];
let array5 = [];
let array6 = [];

export const Badge = ({ name, id, length }) => {
    const theme = useTheme();
    useEffect(() => {
        randomColor();
    }, []);
    const randomColor = () => {
        let a = 0;
        let b = 1;
        let c = 2;
        let d = 3;

        for (let i = length || 100; i >= 0; i -= 4) {
            array1.push(a + i);
            array2.push(b + i);
            array3.push(c + i);
            array4.push(d + i);
        }
    };

    let getInitials = name
        ?.split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "");
    var BackgroundColor =
        (array1.some((data) => data == id) && "#00A9FF") ||
        (array2.some((data) => data == id) && "#54A914") ||
        (array3.some((data) => data == id) && "#FF5558") ||
        (array4.some((data) => data == id) && "#9155FD");

    const styles = {
        state: {
            width: "40px",
            height: "40px",
            borderRadius: "60%",
            backgroundColor: BackgroundColor,
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            padding: "10px",
            color: "#ffff",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "uppercase",
        },
    };
    return <span style={styles.state}>{sliceContent(getInitials, 3, true)}</span>;
};

export const sliceContent = (str, size, hideDot) => {
    if (str) {
        let len = str.length;
        if (len > size) {
            return hideDot ? str.slice(0, size) : str.slice(0, size) + "...";
        } else {
            return str;
        }
    } else {
        return str;
    }
};

export function presence(string) {
    if (string === "undefined") {
        return "-";
    }
    return string?.length > 0 ? string : "-";
}

export const RoleColor = (value) => {
    const categoryColors = {
        success: ["video", "approved", "public", "responded", "text"],
        warning: ["multi", "pending", "direct", "scale",],
        primary: ["private", "shared", "branch"],
        info: ["explainer", "audio"],
        error: ["both"],
        secondary: ["rating"]
    };

    const defaultColor = "info";

    for (const category in categoryColors) {
        if (categoryColors[category].includes(value)) {
            return category;
        }
    }

    return defaultColor;
};

export function titleize(str) {
    if (!str) {
        return str;
    }

    return str?.split(" ")
        .map(function (string) {
            return string?.charAt(0).toUpperCase() + string.slice(1);
        })
        .join(" ")
        .split("_")
        .map(function (string) {
            return string?.charAt(0).toUpperCase() + string.slice(1);
        })
        .join(" ");
}

export function timeDiff(startTime, endTime) {
    if (!startTime || !endTime) return "-";
    if (startTime < endTime)
        return moment.duration(moment(endTime).diff(moment(startTime))).humanize();
    else
        return `${moment.duration(moment(endTime).diff(moment(startTime))).humanize(1)} ago`;
}

export const linkRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
// /^((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))([a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?(\/.*))?$/gm;

export const CrmLinkRegex =
    /^((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))([a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?(\/.*))?$/gm;

export const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9.]+$/;
    if (!regex.test(keyValue)) {
        event.preventDefault();
    }
};



export const CustomizedChip = ({ value, key, sx }) => {

    return <Chip label={value} variant='filled' sx={sx} />;

}

// export function timeDiff(startTime, endTime) {
//     if (!startTime || !endTime) return "-";

//     if (startTime < endTime) {
//         const duration = moment.duration(moment(endTime).diff(moment(startTime)));
//         return duration.humanize() + " ago";
//     } else {
//         const duration = moment.duration(moment(startTime).diff(moment(endTime)));
//         return duration.humanize() + " ago";
//     }
// }