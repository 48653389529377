// theme.js
import createCache from '@emotion/cache';
import { createTheme } from '@mui/material/styles';
// import { createCache } from '@emotion/cache';
import stylisRTL from 'stylis-plugin-rtl';

export const createMyTheme = (direction) => createTheme({
    direction,
    typography: {
        h1: {
            fontSize: '2rem',
        },
    },
});

export const createRtlCache = () => createCache({
    key: 'muirtl',
    stylisPlugins: [stylisRTL],
});
