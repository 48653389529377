import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
    CircularProgress,
    FormControl,
    createFilterOptions,
    Autocomplete,
    FormHelperText,
    Typography,
    TextField,
    MenuItem,
    IconButton,
    ThemeProvider,
    CssBaseline,
} from "@mui/material";
import { presence, sliceContent } from "utils/helpers";
import styled from "styled-components";
import IconifyIcon from "components/icons";
import { CacheProvider } from "@emotion/react";
import { createMyTheme, createRtlCache } from "components/theme";
import createCache from "@emotion/cache";
const filter = createFilterOptions();

const TextWrapper = styled.p`
  margin: 0px;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(58, 53, 65, 0.87);
  font-weight: 600;
  text-decoration: none;
`;

export default function SearchableSelect({
    option,
    value,
    onchange,
    size,
    style,
    label,
    fullWidth,
    onblur,
    error,
    helperText,
    clearable,
    loading,
    onInputChange,
    imageView,
    multiple,
    name,
    disableCloseOnSelect,
    selectClearValue,
    disabled,
    lang
}) {


    const [filteredOption, setFilteredOption] = useState([]);
    const [open, setOpen] = useState(false);

    const direction = useMemo(() => (lang === 'ar' ? 'rtl' : 'ltr'), [lang]);
    const theme = useMemo(() => createMyTheme(direction), [direction]);

    const cache = useMemo(() => {
        return direction === 'rtl' ? createRtlCache() : createCache({ key: 'mui' });
    }, [direction]);



    useEffect(() => {
        setFilteredOption(option);
    }, [option, value]);

    return (

        // <CacheProvider value={cache}>
        //     <ThemeProvider theme={theme}>
        //         <CssBaseline />
        // <div dir={direction}>

        <FormControl fullWidth>
            <Autocomplete
                id="autocomplete-asynchronous-request"
                open={open}
                name={name}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                disableCloseOnSelect={disableCloseOnSelect}
                multiple={multiple}
                size={size}
                disableClearable={true}
                fullWidth={fullWidth}
                onBlur={() => {
                    onblur();
                    // onInputChange("");
                }}

                loading={loading}
                disabled={disabled}
                style={style}
                value={value}
                filterSelectedOptions={multiple}
                options={filteredOption}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                // filterOptions={filterOptions}
                getOptionLabel={(option) => option?.label}
                // onInputChange={onInputChange}
                onChange={(event, newValue) => {
                    if (typeof newValue === "string") {

                        onchange(newValue);
                        return;
                    }
                    if (newValue && newValue.inputValue) {
                        onchange(newValue.inputValue);
                        return;
                    }
                    onchange(newValue);
                }}
                renderOption={(props, option) => (
                    <li {...props} key={option?.value}>
                        {imageView ? (
                            <div style={{ display: "flex", gap: "15px" }}>
                                <img
                                    style={{
                                        borderRadius: "50px",
                                        height: "40px",
                                        width: "40px",
                                        objectFit: "fill",
                                    }}
                                    src={option?.image || "/images/avatacontractorList1.png"}
                                />
                                <div>
                                    <TextWrapper>{option?.label}</TextWrapper>
                                    <Typography variant="body2">
                                        {presence(option?.email)}
                                    </Typography>
                                </div>
                            </div>
                        ) : (
                            sliceContent(option?.label, 30)
                        )}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        error={error}
                        {...params}
                        value={value}
                        label={label}
                        onBlur={() => {
                            onblur();
                            // onInputChange("");
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Fragment>
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}

                                    {!clearable && value ? (
                                        <IconButton onClick={() => onchange(null)}>
                                            <IconifyIcon icon={"mdi-close"} fontSize={20} />
                                        </IconButton>
                                    ) : null}
                                    {selectClearValue && value && !clearable ? (
                                        <IconButton onClick={() => selectClearValue(name)}>
                                            <IconifyIcon icon={"mdi-close"} fontSize={20} />
                                        </IconButton>
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            ),
                        }}

                    />
                )}
            />
            {error && (
                <FormHelperText sx={{ color: "error.main" }}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>

        // </div>
        //     </ThemeProvider>
        // </CacheProvider>
    );
}
