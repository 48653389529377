import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, FormControl, FormHelperText, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'

import ResumeImage from "../asserts/images/hiring.dd910c9029995d0014241720a1c3f674.svg"
import { LabelsList, WorkListArb, WorkListEng } from 'formDetails';
import TextInput from 'components/inputs/TextInput';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import SearchableSelect from 'components/inputs/searchableSelect';
import CountyCode from "../../src/countryList.json"
import SelectField from 'components/inputs/selectFeild';
import MobilePicker from 'components/inputs/datePicker';
import moment from 'moment';

import { sliceContent } from 'utils/helpers';
import { uploadEmpDetailsAPI } from 'api/resume';
import { useLocation, useNavigate } from 'react-router-dom';
import { errorHandler } from 'utils/errorHandler';
import IconifyIcon from 'components/icons';
import NewSelect from 'components/inputs/newSelect';

const schema = yup.object().shape({
    name: yup.string().required("please enter name"),
    dob: yup.date().nullable().required("please select DOB"),
    address: yup.string().required("please enter your addres"),
    mobile: yup.string().required("please enter mobile number"),
    hasExperiencetoWork: yup.object().nullable().required("please select job type"),
    resume: yup.array().of(yup.mixed().required()).nullable().required("Please upload resume")

})

const defaultValues = {
    name: "",
    dob: null,
    gender: "male",
    age: "",
    address: "",
    nationality: { value: "oman", label: "Oman" },
    countryCode: { value: "+968", label: "+968" },
    mobile: "",
    hasExperiencetoWork: null,
    resume: null
}


const GenderOpt = [
    {
        label: "Male", value: "male"
    },
    {
        label: "Female", value: "female"
    }
]

function HomePage({ lang }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate()


    const { english, arabic } = LabelsList
    const [disableBtn, setDisableBtn] = useState(false)
    const [listCountry, setListCountry] = useState([])
    const [mobileCode, setMobileCode] = useState([])


    console.log(lang, "1qwqwqwqqw");

    const alignFun = () => {
        return lang === "ar" ? "right" : "left"
    }

    const {
        register,
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
        reset,
        setError
    } = useForm({
        defaultValues,
        mode: "onBlur",
        resolver: yupResolver(schema),
    });



    useEffect(() => {

        let CountryList = []
        let CodeList = []
        CountryList = CountyCode?.map((val) => {
            return ({
                label: val?.name, value: val?.name.toLowerCase()
            })
        })
        CodeList = CountyCode?.map((val) => {
            return ({
                label: val?.dial_code, value: val?.dial_code
            })
        })
        setListCountry(CountryList)
        setMobileCode(CodeList)
    }, [])

    useEffect(() => {
        if (watch('dob')) {
            const dob = moment(new Date(watch('dob'))); // Replace "1998-05-28" with your dynamic date of birth
            const now = moment();
            const age = now.diff(dob, 'years');

            setValue('age', age)
        }
    }, [watch('dob')])


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const aliasName = query.get('alias_name');


    const onSubmit = (data) => {
        setDisableBtn(true)
        const { name, dob, gender, age, address, nationality, countryCode, mobile, hasExperiencetoWork, resume } = data

        const form = new FormData();
        form.append('profiles[first_name]', name)
        form.append('profiles[dob]', dob)
        form.append('profiles[gender]', gender)
        form.append('profiles[age]', age)
        form.append('profiles[address]', address)
        form.append('profiles[country]', nationality?.value)
        form.append('profiles[countrycode]', countryCode?.value)
        form.append('profiles[phone]', mobile)
        form.append('profiles[hasexperiencetowork]', hasExperiencetoWork?.value)
        form.append('alias_name', aliasName ? aliasName : 'default')
        form.append('resume_file', resume[0])

        uploadEmpDetailsAPI(form)
            .then(({ data }) => {
                navigate(`/success_page`)
                setDisableBtn(false)
            })
            .catch((error) => {
                if (error?.response) {
                    errorHandler(error.response);
                } else {
                    errorHandler(error);
                }
                setTimeout(() => {
                    setDisableBtn(false)
                }, 3000)
            })

    }

    const direction = useMemo(() => (lang === 'ar' ? 'rtl' : 'ltr'), [lang]);




    return (
        <div>

            <Grid container spacing={2}>
                {!isMobile && (
                    <Grid item xs={12} sm={7}>
                        <img
                            src={ResumeImage}
                            alt="Placeholder"
                            style={{ width: '80%', height: 'auto' }}
                        />
                    </Grid>
                )}
                <Grid sx={{ background: "#F6F6F6", borderRadius: '10px', overflowY: 'scroll' }} item xs={12} sm={isMobile ? 12 : 5}>
                    <div className={` flex justify-center items-center`}>
                        <Typography fontSize={"30px"} fontWeight={600} color={"#DB3B44"} alignItems={'center'} variant='h6'>{lang === "eng" ? "Job Application" : "طلب وظيفي"}</Typography>
                    </div>
                    <div
                        dir={direction}
                    >
                        <Box

                            sx={{ display: 'flex', flexDirection: 'column', gap: '10px', m: 2 }}

                        >
                            <form
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit(onSubmit)}
                                style={{ display: 'flex', flexDirection: "column", gap: '10px' }}
                            >
                                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                                    <label style={{
                                        color: "#777F8F", fontSize: "16px", marginBottom: '5px',
                                    }}
                                    >{lang == "eng" ? english?.name : arabic?.name} <span style={{ color: '#DB3B44' }}>*</span></label>
                                    <FormControl style={{ flex: 1 }}>
                                        <Controller
                                            name="name"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <TextInput
                                                    value={value}
                                                    onblur={onBlur}
                                                    onchange={(e) => { onChange(e) }}
                                                    error={Boolean(errors.name)}
                                                    helperText={Boolean(errors.name) && errors.name.message}
                                                    fullWidth={true}
                                                    size={'small'}
                                                    sx={{}}
                                                />)}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                                    <label style={{ color: "#777F8F", fontSize: "16px", marginBottom: '5px', }}>{lang == "eng" ? english?.nationality : arabic?.nationality} <span style={{ color: '#DB3B44' }}>*</span></label>
                                    <FormControl fullWidth>
                                        <Controller
                                            name={`nationality`}
                                            control={control}
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <SearchableSelect

                                                    value={value}
                                                    onchange={(e) => {
                                                        onChange(e);
                                                        setDisableBtn(false);

                                                    }}
                                                    size={'small'}
                                                    fullWidth={true}
                                                    option={listCountry}
                                                    onblur={onBlur}
                                                    error={Boolean(errors.nationality)}
                                                    clearable={false}
                                                    lang={lang}

                                                />
                                            )}
                                        />
                                        {errors.nationality && (
                                            <FormHelperText sx={{ color: "error.main" }}>
                                                {errors.nationality.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                                    <label style={{ color: "#777F8F", fontSize: "16px", marginBottom: '5px', }}>{lang == "eng" ? english?.gender : arabic?.gender} <span style={{ color: '#DB3B44' }}>*</span></label>
                                    <FormControl fullWidth>
                                        <Controller
                                            name={`gender`}
                                            control={control}
                                            render={({ field: { value, onChange, onBlur } }) => (

                                                <NewSelect
                                                    size={"small"}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        setDisableBtn(false);

                                                    }}
                                                    value={value}
                                                    option={GenderOpt}
                                                    lang={lang}

                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                                    <label style={{ color: "#777F8F", fontSize: "16px", marginBottom: '5px', }}>{lang == "eng" ? english?.dob : arabic?.dob} <span style={{ color: '#DB3B44' }}>*</span></label>
                                    <FormControl fullWidth>
                                        <Controller
                                            name={`dob`}
                                            control={control}
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <MobilePicker
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        setDisableBtn(false);

                                                    }}
                                                    error={Boolean(errors.dob)}
                                                    lang={lang}
                                                />
                                            )}
                                        />
                                        {errors.dob && (
                                            <FormHelperText sx={{ color: "error.main" }}>
                                                {errors.dob.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                                    <label style={{ color: "#777F8F", fontSize: "16px", marginBottom: '5px', }}>{lang == "eng" ? english?.age : arabic?.age} <span style={{ color: '#DB3B44' }}>*</span></label>
                                    <FormControl style={{ flex: 1 }}>
                                        <Controller
                                            name="age"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <TextInput
                                                    value={value}
                                                    onblur={onBlur}
                                                    onchange={(e) => {
                                                        onChange(e);
                                                        setDisableBtn(false);

                                                    }}
                                                    error={Boolean(errors.name)}

                                                    fullWidth={true}
                                                    size={'small'}
                                                    sx={{}}
                                                />)}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                                    <label style={{ color: "#777F8F", fontSize: "16px", marginBottom: '5px', }}>{lang == "eng" ? english?.address : arabic?.address} <span style={{ color: '#DB3B44' }}>*</span></label>
                                    <FormControl style={{ flex: 1 }}>
                                        <Controller
                                            name="address"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <TextInput
                                                    value={value}
                                                    onblur={onBlur}
                                                    onchange={(e) => {
                                                        onChange(e);
                                                        setDisableBtn(false);

                                                    }}
                                                    error={Boolean(errors.address)}
                                                    helperText={Boolean(errors.address) && errors.address.message}
                                                    fullWidth={true}
                                                    size={'small'}
                                                    multiline={true}
                                                    rows={2}
                                                    sx={{}}
                                                />)}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                                    <label style={{ color: "#777F8F", fontSize: "16px", marginBottom: '5px', }}>{lang == "eng" ? english?.mobile : arabic?.mobile} <span style={{ color: '#DB3B44' }}>*</span></label>
                                    <Grid container spacing={1} sx={{ marginLeft: '0px' }}>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <Controller
                                                    name={`countryCode`}
                                                    control={control}
                                                    render={({ field: { value, onChange, onBlur } }) => (
                                                        <SearchableSelect

                                                            value={value}
                                                            onchange={(e) => {
                                                                onChange(e);
                                                                setDisableBtn(false);

                                                            }}
                                                            size={'small'}
                                                            fullWidth={true}
                                                            option={mobileCode}
                                                            onblur={onBlur}


                                                        />
                                                    )}
                                                />

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <FormControl style={{ flex: 1 }} fullWidth={true}>
                                                <Controller
                                                    name="mobile"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange, onBlur } }) => (
                                                        <TextInput
                                                            value={value}
                                                            onblur={onBlur}
                                                            onchange={(e) => {
                                                                onChange(e);
                                                                setDisableBtn(false);

                                                            }}
                                                            error={Boolean(errors.mobile)}
                                                            helperText={Boolean(errors.mobile) && errors.mobile.message}
                                                            fullWidth={true}
                                                            size={'small'}
                                                            sx={{}}
                                                        />)}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                                    <label style={{ color: "#777F8F", fontSize: "16px", marginBottom: '5px', }}>{lang == "eng" ? english?.hasExperiencetoWork : arabic?.hasExperiencetoWork} <span style={{ color: '#DB3B44' }}>*</span></label>
                                    <FormControl fullWidth>
                                        <Controller
                                            name={`hasExperiencetoWork`}
                                            control={control}
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <SearchableSelect

                                                    value={value}
                                                    onchange={(e) => {
                                                        onChange(e);
                                                        setDisableBtn(false);

                                                    }}
                                                    size={'small'}
                                                    fullWidth={true}
                                                    option={lang === "eng" ? WorkListEng : WorkListArb}
                                                    onblur={onBlur}
                                                    error={Boolean(errors.hasExperiencetoWork)}
                                                    lang={lang}
                                                />
                                            )}
                                        />
                                        {errors.hasExperiencetoWork && (
                                            <FormHelperText sx={{ color: "error.main" }}>
                                                {errors.hasExperiencetoWork.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>
                                <label style={{ color: "#DB3B44", fontSize: "18px", marginBottom: '5px', }}>{lang == "eng" ? "Attachment CV" : "مرفق السيرة الذاتية"} <span style={{ color: '#DB3B44' }}>*</span></label>
                                <label style={{ color: "#777F8F", fontSize: "16px", marginBottom: '5px', }}>{lang == "eng" ? "Resume" : "سيرة ذاتية"} <span style={{ color: '#DB3B44' }}>*</span></label>



                                <FormControl fullWidth>
                                    <Controller
                                        name={`resume`}
                                        control={control}
                                        render={({ field: { value, onChange, onBlur } }) => (
                                            <Button component="label" sx={{ border: '2px dashed #DEE4F0', padding: '15px', textAlign: "center", background: "#F5F6FA", display: 'flex', alignItems: 'center', gap: '2px' }} >

                                                <span>{lang === "eng" ? "Browse documents" : "تصفح المستندات"}</span>  <span style={{ color: "#DB3B44" }}>(JPEG, PDF, DOC, PNG, JPG)</span>
                                                <input hidden type="file" accept=".pdf,.png,.jpg,.jpeg" onChange={(e) => {
                                                    onChange([...e.target.files]);
                                                    setDisableBtn(false);
                                                }

                                                } />
                                            </Button>
                                        )}
                                    />
                                    {watch('resume') &&
                                        <Box
                                            sx={{
                                                border: '1px solid #949494',
                                                mt: 2,
                                                padding: '10px',
                                                borderRadius: '8px',
                                                display: "flex",
                                                alignItems: 'center',
                                                gap: '10px',

                                            }}
                                        >
                                            <IconifyIcon icon={'mdi-file-document-multiple'} color={'#DB3B44'} />
                                            <Typography className='file-name' color={`${theme.palette.mode == "light" ? '#5369f8' : "'#5369f8'"}`}>
                                                {sliceContent((watch('resume')?.[0]?.name), 20)}
                                            </Typography>

                                        </Box>
                                    }
                                    {errors.resume && (
                                        <FormHelperText sx={{ color: "error.main" }}>
                                            {errors.resume.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mt: 3
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => { reset(defaultValues) }}
                                        sx={{
                                            color: "#333333",
                                            border: '1px solid #333333',
                                            textTransform: 'capitalize',
                                            flexGrow: 1,
                                            maxWidth: '150px'
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            pointerEvents: disableBtn ? "none" : "",
                                            background: "#DB3B44",
                                            textTransform: 'capitalize',
                                            flexGrow: 2,
                                            maxWidth: '300px',
                                            '&:hover': {
                                                background: "#DB3B44"
                                            }
                                        }}
                                    >
                                        Submit Application
                                    </Button>
                                </Box>
                            </form>
                        </Box>

                    </div>

                </Grid>
            </Grid>



        </div>
    )
}

export default HomePage