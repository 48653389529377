import toast from "react-hot-toast";

export const errorHandler = async (error) => {
    let status_code = error && error.status;
    let message =
        error && error.data
            ? error.data.data
                ? error.data.data[0]
                : error.data.message
            : error.message;

    switch (status_code) {
        case 401:
            return toast.error(message || "Access Denied.!!!");

        case 403:
            return toast.error(message || "Access Denied.!!!");

        case 404:
            return toast.error(message || "Not Found.!!!");

        case 422:
            return toast.error(message || "Invalid Data.!!!");

        case 429:
            return toast.error(message || "Too Many Request.!!!");

        case 400:
            return toast.error(message || "Bad Request.!!!");

        case 500:
            return toast.error(message || "Internal Server Error.!!!");

        case 502:
            return toast.error(message || "Could not fetch data from remote.!!!");

        default:
            return toast.error(message || "Something is not right.!!");
    }
};
