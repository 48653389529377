import React from 'react'
import { Box } from '@mui/material'
import FooterImg from '../asserts/images/foooter.png'


function Footer({
    position = ""
}) {
    return (
        <div>
            <Box
                component="footer"
                sx={{
                    width: '100%',
                    position: position,
                    bottom: 0,
                    left: 0,
                    backgroundColor: '#F6F6F6',
                    textAlign: 'center',
                    padding: '10px 0',
                    borderTop: '1px solid #e7e7e7',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >

                <img
                    alt='img'
                    src={FooterImg}
                    height={'150px'}
                    width={'150px'}
                />
            </Box>
        </div>
    )
}

export default Footer