import connection from ".";
// import makeRequest from ".";

// export function getJobAPI() {
//   return makeRequest({
//     uri: `/openings`,
//   });
// }

// export function uploadEmpDetailsAPI(requestBody) {
//   return makeRequest({
//     uri: '/employments/upload',
//     method: 'POST',
//     body: requestBody
//   })
// }

export const getJobAPI = async () => {
  return connection.get('/openings')
}

export const uploadEmpDetailsAPI = async (body) => {
  return connection.post('/employments/upload', body)
}