// App.js
import React, { useState, useMemo } from 'react';
import { ThemeProvider, CssBaseline, Typography, Select, MenuItem } from '@mui/material';
import { CacheProvider } from '@emotion/react';
// import { createMyTheme, createRtlCache } from './theme';
import createCache from '@emotion/cache';
import { createMyTheme, createRtlCache } from 'components/theme';
import styled from "styled-components";


function NewSelect(
    {
        label,
        variant,
        fullWidth,
        option,
        multiple,
        onChange,
        style,
        value,
        helperText,
        error,
        onBlur,
        imageView,
        color,
        size,
        disable,
        selectClearValue,
        name,
        readOnly,
        id,
        lang
    }
) {

    const direction = useMemo(() => (lang === 'ar' ? 'rtl' : 'ltr'), [lang]);
    const theme = useMemo(() => createMyTheme(direction), [direction]);

    const cache = useMemo(() => {
        return direction === 'rtl' ? createRtlCache() : createCache({ key: 'mui' });
    }, [direction]);



    const TextWrapper = styled.p`
    margin: 0px;
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: 0.15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(58, 53, 65, 0.87);
    font-weight: 600;
    text-decoration: none;
  `;


    return (
        // <CacheProvider value={cache}>
        //     <ThemeProvider theme={theme}>
        //         <CssBaseline />
        // <div dir={direction}>
        <Select
            fullWidth={true}
            name={name}
            id={id}
            label={label}
            value={value}
            style={style}
            multiple={multiple}
            onChange={onChange}
            onBlur={onBlur}
            color={color}
            size={size}
            disabled={disable}
            // sx={{ textAlign: "right" }}
            error={error}
            inputProps={{ readOnly: readOnly }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "right"
                },
                getContentAnchorEl: null
            }}

        >
            {option.length > 0 ? (
                imageView ? (
                    option.map((obj, i) => {
                        return (
                            <MenuItem key={i} value={obj} >
                                <div style={{ display: "flex", gap: "15px" }}>
                                    <img
                                        style={{ width: "40px", borderRadius: 50 }}
                                        src={
                                            obj?.image[0]?.url ||
                                            "https://docs-assets.katomaran.tech/images/images/smart-condo/res_man/2022/10/res_man.png"
                                        }
                                    />
                                    <div>
                                        <TextWrapper>{obj.name}</TextWrapper>
                                        <TextWrapper
                                            className="res-option"
                                            style={{ color: "rgba(58, 53, 65, 0.68)" }}
                                        >
                                            {obj.phone}
                                        </TextWrapper>
                                    </div>
                                </div>
                            </MenuItem>
                        );
                    })
                ) : (
                    option.map((item, index) => (
                        <MenuItem sx={{ textAlign: "right" }} disabled={item?.disabled} id={index} key={index} value={item?.value}>
                            {item?.label}
                        </MenuItem>
                    ))
                )
            ) : (
                <MenuItem value="">
                    <Typography textAlign={"center"} color={"text.secondary"}>
                        No Option
                    </Typography>
                </MenuItem>
            )}
        </Select>

        // </div>
        //     </ThemeProvider>
        // </CacheProvider>
    );
}

export default NewSelect;
